import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { getPageInfo } from 'utils';
import { BLOG_KEYWORDS } from 'globals';

import { BlogList, CategoryMenuBlog } from 'components/Blog';
import { BeInTouch } from 'components/Paragraphs/Types';
import { SEO, Layout, SearchBar, Pagination } from 'components';

const BlogListTemplate = ({
  location,
  pageContext: { currentPage, numPages },
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    allNodeBlog: { edges: posts },
    allTaxonomyTermBlogCategory: { edges: category },
    nodePage,
  },
}) => {
  const {
    paginationPath,
    isFirst,
    isLast,
    prevPage,
    nextPage,
    metaTitle,
    metaDescription,
  } = getPageInfo(location.pathname, currentPage, numPages);

  return (
    <Layout
      location={location}
      title={siteTitle}
      type="blog"
      nodeTitle="Blog"
      isDefaultBreadcrumbs={nodePage?.field_automatic_breadcrumbs}
      customBreadcrumbs={nodePage?.relationships?.field_breadcrumbs}
    >
      <SEO
        title={metaTitle}
        keywords={BLOG_KEYWORDS}
        currentUrl={location.pathname}
        description={metaDescription}
      />
      <h1 className="page-hidden-title">Blog</h1>
      <div className="page-top">
        <CategoryMenuBlog categories={category} />
        <SearchBar />
      </div>
      <BlogList posts={posts} />
      <Pagination
        isFirst={isFirst}
        numPages={numPages}
        currentPage={currentPage}
        isLast={isLast}
        nextPage={nextPage}
        prevPage={prevPage}
        paginationPath={paginationPath}
      />
      <BeInTouch title="Want To Work With Us?" buttonTitle="Let’s Talk" blueBg="blue-bg" />
    </Layout>
  );
};

BlogListTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.object,
    }),
    allNodeBlog: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allTaxonomyTermBlogCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
    nodePage: PropTypes.object,
  }),
};

export default BlogListTemplate;

export const query = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allNodeBlog(sort: { fields: [field_last_update], order: DESC }, limit: $limit, skip: $skip) {
      ...AllBlog
      nodes {
        field_automatic_breadcrumbs
        relationships {
          field_breadcrumbs {
            id
            field_link {
              title
              uri
            }
          }
        }
      }
    }
    allTaxonomyTermBlogCategory {
      ...AllTaxonomyTermBlogCategory
    }
  }
`;
